<script>
import appConfig from "@/app.config";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { required } from "vuelidate/lib/validators";

/**
 * Form Extra Page component
 */
export default {
  page: {
    title: "Formulário de Página Extra",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      editor: ClassicEditor,    
      form: {
        title: '',
        slug: '',
        showInMenu: 0,
        content: '',
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      title: { required },
      slug: { required },
      showInMenu: { required },
      content: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    emitCad(event) {
      this.$emit('newCad', event);
    }
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row mb-3">
      <div class="col-12 col-md-4 form-group">
          <label for="modalAddPageTitle">Título</label>
          <input 
            type="text" 
            id="modalAddPageTitle" 
            placeholder="Insira o título"
            v-model="form.title" 
            name="modalAddPageTitle" 
            class="form-control"            
            :class="{
              'is-invalid': submitted && $v.form.title.$error,
            }"
          >          
          <div
            v-if="submitted && $v.form.title.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.title.required"
              >Por favor, digite o título.</span
            >
          </div>
      </div>
      <div class="col-12 col-md-4 form-group">
          <label for="modalAddPageSlug">Slug</label>
          <input 
            type="text" 
            id="modalAddPageSlug" 
            placeholder="Insira o slug"
            v-model="form.slug" 
            name="modalAddPageSlug" 
            class="form-control" 
            :class="{
              'is-invalid': submitted && $v.form.slug.$error,
            }"
          >          
          <div
            v-if="submitted && $v.form.slug.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.slug.required"
              >Por favor, digite o slug.</span
            >
          </div>
      </div>
      <div class="col-12 col-md-4 form-group">
          <label for="modalAddPageShowInMenu">Exibir no menu?</label>
          <select id="modalAddPageShowInMenu" 
            v-model="form.showInMenu" 
            name="modalAddPageShowInMenu" 
            class="form-control"            
            :class="{
              'is-invalid': submitted && $v.form.slug.$error,
            }"
          >
              <option value="1">Sim</option>
              <option value="0">Não</option>
          </select>
          <div
            v-if="submitted && $v.form.showInMenu.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.showInMenu.required"
              >Por favor, selecione uma opção.</span
            >
          </div>
      </div>
    </div> 
    <div class="row mb-3">
      <div class="col-lg-12">
        <label class="col-md-3 form-label"> Conteúdo da Página </label>
        <ckeditor 
          v-model="form.content" 
          :editor="editor" 
          placeholder="Conteúdo da Página"
          :class="{
            'is-invalid': submitted && $v.form.content.$error,
          }"        
        ></ckeditor>
        <div
          v-if="submitted && $v.form.content.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.content.required"
            >Por favor, digite o conteúdo.</span
          >
        </div>
      </div>
    </div>
    <br />
    <div class="mt-2 text-end">
      <b-button variant="light" @click="emitCad(false)">Fechar</b-button>
      <b-button type="submit" variant="info" class="ms-1">Cadastrar</b-button>
    </div>
  </form>
</template>
