var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-md-4 form-group"},[_c('label',{attrs:{"for":"modalAddPageTitle"}},[_vm._v("Título")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.title.$error,
          },attrs:{"type":"text","id":"modalAddPageTitle","placeholder":"Insira o título","name":"modalAddPageTitle"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.title.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.title.required)?_c('span',[_vm._v("Por favor, digite o título.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-12 col-md-4 form-group"},[_c('label',{attrs:{"for":"modalAddPageSlug"}},[_vm._v("Slug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.slug),expression:"form.slug"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.slug.$error,
          },attrs:{"type":"text","id":"modalAddPageSlug","placeholder":"Insira o slug","name":"modalAddPageSlug"},domProps:{"value":(_vm.form.slug)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "slug", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.slug.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.slug.required)?_c('span',[_vm._v("Por favor, digite o slug.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-12 col-md-4 form-group"},[_c('label',{attrs:{"for":"modalAddPageShowInMenu"}},[_vm._v("Exibir no menu?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.showInMenu),expression:"form.showInMenu"}],staticClass:"form-control",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.slug.$error,
          },attrs:{"id":"modalAddPageShowInMenu","name":"modalAddPageShowInMenu"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "showInMenu", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sim")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Não")])]),(_vm.submitted && _vm.$v.form.showInMenu.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.showInMenu.required)?_c('span',[_vm._v("Por favor, selecione uma opção.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"col-md-3 form-label"},[_vm._v(" Conteúdo da Página ")]),_c('ckeditor',{class:{
          'is-invalid': _vm.submitted && _vm.$v.form.content.$error,
        },attrs:{"editor":_vm.editor,"placeholder":"Conteúdo da Página"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),(_vm.submitted && _vm.$v.form.content.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.content.required)?_c('span',[_vm._v("Por favor, digite o conteúdo.")]):_vm._e()]):_vm._e()],1)]),_c('br'),_c('div',{staticClass:"mt-2 text-end"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.emitCad(false)}}},[_vm._v("Fechar")]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"info"}},[_vm._v("Cadastrar")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }