const tableData = [
  {
    id: 1,
    title: 'Recruiting Mantotalname',
    slug: 'dont know',
    status: 'A',
    show_menu: true,
  }, {
    id: 2,
    title: 'Quality Engineer',
    slug: 'dont know',
    status: 'I',
    show_menu: false,
  }, {
    id: 3,
    title: 'Senior Editor',
    slug: 'dont know',
    status: 'A',
    show_menu: true,
  }, {
    id: 4,
    title: 'Programmer II',
    slug: 'dont know',
    status: 'A',
    show_menu: true,
  },
  {
    id: 5,
    title: 'Data Coordiator',
    slug: 'dont know',
    status: 'I',
    show_menu: false,
  }, {
    id: 6,
    title: 'Data Coordiator',
    slug: 'dont know',
    status: 'I',
    show_menu: false,
  },
]

export { tableData };
